export const assignSchemaEntity = (obj, key, val, originalInput, schema) => {
  const itemSchema = schema && schema.getItemSchema ? schema.getItemSchema() : schema;
  const removeProps = itemSchema && itemSchema.getMeta && itemSchema.getMeta('removeProps');

  if (removeProps && Array.isArray(removeProps) && removeProps.indexOf(key) >= 0) {
    return;
  }

  obj[key] = val;
};
